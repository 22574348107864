import React, { Component } from 'react';

class Fault extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">Network Fault ⚠️</p>
        <p className="fm-max-text">We’re currently experiencing a network issue affecting some scooters, which may prevent rides from starting. We sincerely apologise for the inconvenience and are working to resolve this as quickly as possible.</p>
        <p className="fm-max-text">If you’re impacted, please reach out to our support team via the “Support” tab in the Flamingo app or email us at <a href="mailto:hello@flamingo.co.nz">hello@flamingo.co.nz</a>.</p>
        <p className="fm-max-end">The Flamingo Team</p>
        <br />
      </div>
    );
  }
}

export default Fault;
