import React, { Component } from 'react';

class GardensMagic extends Component {
  render() {
    return (
      <div className="fm-max">
        <img className="fm-max-photo" alt="Gardens Magic" src="https://storage.googleapis.com/flamingo-static/images/parking-area/wn-gardens-magic-content.jpg" />
        <p className="fm-max-title">Gardens Magic</p>
        <p className="fm-max-text">Celebrate summer with <b>Gardens Magic</b>, where the Wellington Botanic Garden ki Paekākā comes alive with music, lights and fun for all ages! From <b>7–26 January</b>, enjoy:</p>
        <p className="fm-max-text">🎶 <b>Concert Series</b> | Tues–Sun, 7pm–9:15pm</p>
        <p className="fm-max-text">🌟 <b>Dazzling Light Displays</b> | Daily, 9pm–10:30pm</p>
        <p className="fm-max-text">🌱 <b>Kids' Garden Trail</b> | Daily, 9am–5pm</p>
        <br/>
        <p className="fm-max-text"><b>📖 Explore the Programme</b></p>
        <p className="fm-max-text">Plan your visit! View the full Gardens Magic Concert Series Programme <a href="https://wellington.govt.nz/-/media/news-and-events/upcoming-events/events/files/garden-magic/gardens-magic-concert-series-programme-2025.pdf" style={{ color: '#ff206e' }}>here</a>.</p>
        <br/>
        <p className="fm-max-text"><b>⭐️ Special Offer</b></p>
        <p className="fm-max-text">Use promo code <b>GARDENSMAGIC</b> in the Flamingo app for <b>15 minutes of free credit</b>. Redeem it on the Payment screen and glide into the magic!</p>
        <br/>
        <p className="fm-max-text"><b>🛴 Free Flamingo Convoy</b></p>
        <p className="fm-max-text">Join us for a free ride to the gardens! Meet at Wellington Railway Station at 6:30pm (Tues–Sun) for an guided trip to Gardens Magic.</p>
        <br/>
        <p className="fm-max-text"><b>🅿️ Park Responsibly</b></p>
        <p className="fm-max-text">Please park in the <b>Designated Parking Area</b> on Glenmore Street (🅿️ in the Flamingo app). Remember, scooters and bikes are <b>not permitted inside the Botanic Gardens</b>.</p>
        <p className="fm-max-end">Make the most of Gardens Magic with Flamingo by your side! 🎇</p>
        <br />
      </div>
    );
  }
}

export default GardensMagic;
