import React, { Component } from 'react';

class PalmyNewYears extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">New Year's Eve 2024</p>
        <p className="fm-max-text">With New Year’s Eve celebrations taking place in The Square, there are some changes to where you can use Flamingo scooters.</p>
        <img className="fm-max-photo" alt="New Year's Eve 2024" src="https://storage.googleapis.com/flamingo-static/images/support/pn-ny-24.jpg" />
        <p className="fm-max-text">For health and safety reasons, <b>scooters cannot be ridden or parked in The Square from 4:30pm</b>. Temporary parking areas have been set up around The Square, just look for the blue [ P ] icon in the Flamingo app.</p>
        <p className="fm-max-text">Our team is hosting a free <b>Learn to Ride</b> event opposite the i-SITE from 5pm to 8pm. Stop by to learn how to safely ride and park a Flamingo scooter and pick up some free credit while you're there!</p>
        <p className="fm-max-text">Heading to The Square? Get <b>10 minutes FREE</b>, just enter the code <b>PALMYNY</b> from the "Payment" tab of the Flamingo app.</p>
        <p className="fm-max-end">Happy New Year,<br />The Flamingo Team</p>
        <br />
      </div>
    );
  }
}

export default PalmyNewYears;
