import React, { Component } from 'react';

class AucklandNewYears extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">New Year's Eve 2024</p>
        <p className="fm-max-text">With New Year’s Eve celebrations happening across Auckland, there are some changes to where you can use Flamingo scooters.</p>
        <img className="fm-max-photo" alt="New Year's Eve 2024" src="https://storage.googleapis.com/flamingo-static/images/support/ak-ny-24.png" />
        <p className="fm-max-text">For health and safety reasons, a curfew area will be in effect from <b>10pm to 3am</b> for the <b>Sky Tower</b> Precinct, <b>Downtown</b>, the <b>Viaduct</b>, <b>K Road</b> and <b>Wynyard Quarter</b>.</p>
        <p className="fm-max-text">Scooters cannot be hired in these areas during this time. <b>Large crowds are expected</b>, so please <b>avoid parking or riding here</b> and ensure scooters are parked safely out of the way of pedestrians.</p>
        <p className="fm-max-text">Heading to the city? Get <b>10 minutes FREE</b>, just enter the code <b>AUCKLANDNY</b> from the "Payment" tab of the Flamingo app.</p>
        <p className="fm-max-end">Happy New Year,<br />The Flamingo Team</p>
        <br />
      </div>
    );
  }
}

export default AucklandNewYears;
