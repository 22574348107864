import React, { Component } from 'react';

class NewYears extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">New Year's Eve 2024</p>
        <p className="fm-max-text">To celebrate New Year's Eve 2024, live music and fireworks will be on the Wellington Waterfront and the Courtenay Carnival will be in the Courtenay Precinct. This means there are a few changes to where you can use Flamingo.</p>
        <img className="fm-max-photo" alt="New Year's Eve 2024" src="https://storage.googleapis.com/flamingo-static/images/support/wn-ny-24.png" />
        <p className="fm-max-text">For health and safety reasons, there are restrictions on where you can ride or park your scooter/bike <b>on the waterfront and Courtenay Precinct</b>.</p>
        <p className="fm-max-text"><b>All Day</b>: You <b>cannot ride or park</b> your scooter/bike in the <b>Courtenay Precinct</b>.</p>
        <p className="fm-max-text"><b>From 8pm</b>: You <b>cannot park</b> your scooter/bike on the <b>waterfront</b>.</p>
        <p className="fm-max-text">There are a number of temporary parking areas set up around the waterfront, just look for the blue [ P ] icon in the Flamingo app.</p>
        <p className="fm-max-text">Heading to the festival? Get <b>10 minutes FREE</b>, just enter the code <b>WELLYNY</b> from the "Payment" tab of the Flamingo app.</p>
        <p className="fm-max-end">Happy New Year,<br />The Flamingo Team</p>
        <br />
      </div>
    );
  }
}

export default NewYears;
