import React, { Component } from 'react';

class SailGP extends Component {
  render() {
    return (
      <div className="fm-max">
        <p className="fm-max-title">SailGP Auckland</p>
        <p className="fm-max-text">SailGP is happening in Auckland this Friday, Saturday and Sunday, bringing a few changes to where you can ride Flamingo scooters.</p>
        <img className="fm-max-photo" alt="SailGP 2025" src="https://storage.googleapis.com/flamingo-static/images/support/aa-sailgp-25.png" />
        <p className="fm-max-text">For health and safety reasons, scooters are <b>not permitted to be ridden or parked</b> in <b>Wynyard Quarter</b> beyond Jellicoe Street and the <b>Eastern Viaduct</b> on Friday, Saturday or Sunday.</p>
        <p className="fm-max-text">Heading to SailGP? Please <b>use one of our designated parking areas</b> on Wynyard Quarter or the Eastern Viaduct. Look for the blue 🅿️ icon in the Flamingo app to find these locations. Parking in these areas keeps pathways clear for the expected crowds.</p>
        <p className="fm-max-text"><b>Enjoy 10 minutes FREE!</b> Enter the code <b>SAILGP</b> in the "Payment" tab of the Flamingo app to get 10 minutes free for your ride.</p>
        <p className="fm-max-end">Safe Riding,<br />The Flamingo Team</p>
        <br />
      </div>
    );
  }
}

export default SailGP;
